<template>
  <b-card no-body class="card-statistics" v-if="statistics != null">
    <b-card-header>
      <b-card-title>Date wise candidate statistics </b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col class="mb-2 mb-md-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="UserIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{
                  statistics.total_candidates ? statistics.total_candidates : 0
                }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Total </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col class="mb-2 mb-md-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon size="24" icon="UserIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{
                  statistics.approved_candidates
                    ? statistics.approved_candidates
                    : 0
                }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Approved </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col class="mb-2 mb-md-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-info">
                <feather-icon size="24" icon="UserIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{
                  statistics.pending_candidates
                    ? statistics.pending_candidates
                    : 0
                }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Pending </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col class="mb-2 mb-md-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-warning">
                <feather-icon size="24" icon="UserIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{
                  statistics.reviewing_candidates
                    ? statistics.reviewing_candidates
                    : 0
                }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Reviewing </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col class="mb-2 mb-md-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-danger">
                <feather-icon size="24" icon="UserIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{
                  statistics.rejected_candidates
                    ? statistics.rejected_candidates
                    : 0
                }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Rejected </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import _ from "lodash";

export default {
  props: ["search"],
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      statistics: null,
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults() {
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/dashboard/filtered/candidate-statistics${queryParams}`)
        .then((res) => {
          this.statistics = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    SearchData: _.debounce(function () {
      this.getResults();
    }, 1000),
  },
  watch: {
    "search.from_date": function (val) {
      this.SearchData();
    },
    "search.to_date": function (val) {
      this.SearchData();
    },
  },
};
</script>
