<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <b-row>
      <b-col lg="2" class="ml-auto">
        <div class="form-group">
          <DatePicker
            v-model="search.from_date"
            type="date"
            placeholder="Created From Date"
            style="width: 100%"
          >
          </DatePicker>
        </div>
      </b-col>
      <b-col lg="2">
        <div class="form-group">
          <DatePicker
            v-model="search.to_date"
            type="date"
            placeholder="Created To Date"
            style="width: 100%"
          >
          </DatePicker>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <candidate-statistics v-if="permission.candidateStatistics" />
        <candidate-statistics-monthly
          :search="search"
          v-if="permission.candidateStatistics"
        />
      </b-col>
      <div class="col-md-3 col-lg-3" v-if="permission.jobStatistics">
        <div class="card card-transaction">
          <div class="card-header">
            <h4 class="card-title text-bold">Job Statistics</h4>
          </div>
          <div class="card-body">
            <div class="transaction-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="transaction-title">Total Jobs</h6>
                </div>
              </div>
              <div class="font-weight-bolder">
                {{ jobReports.totalJobs ? jobReports.totalJobs : "0" }}
              </div>
            </div>
            <hr />

            <div class="transaction-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="transaction-title">Total Applied Jobs</h6>
                </div>
              </div>
              <div class="font-weight-bolder">
                {{
                  jobReports.totalAppliedJobs
                    ? jobReports.totalAppliedJobs
                    : "0"
                }}
              </div>
            </div>
            <hr />

            <div class="transaction-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="transaction-title">Total Proposed Jobs</h6>
                </div>
              </div>
              <div class="font-weight-bolder">
                {{
                  jobReports.totalProposedJobs
                    ? jobReports.totalProposedJobs
                    : "0"
                }}
              </div>
            </div>
            <hr />

            <div class="transaction-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="transaction-title">Pending Candidates</h6>
                </div>
              </div>
              <div class="font-weight-bolder">
                {{ jobReports.pending ? jobReports.pending : "0" }}
              </div>
            </div>
            <hr />

            <div class="transaction-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="transaction-title">Short Listed Candidates</h6>
                </div>
              </div>
              <div class="font-weight-bolder">
                {{ jobReports.shortListed ? jobReports.shortListed : "0" }}
              </div>
            </div>
            <hr />

            <div class="transaction-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="transaction-title">Interviewing Candidates</h6>
                </div>
              </div>
              <div class="font-weight-bolder">
                {{ jobReports.interviewing ? jobReports.interviewing : "0" }}
              </div>
            </div>
            <hr />

            <div class="transaction-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="transaction-title">Selected Candidates</h6>
                </div>
              </div>
              <div class="font-weight-bolder">
                {{ jobReports.selected ? jobReports.selected : "0" }}
              </div>
            </div>
            <hr />

            <div class="transaction-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="transaction-title">Rejected Candidates</h6>
                </div>
              </div>
              <div class="font-weight-bolder">
                {{ jobReports.rejected ? jobReports.rejected : "0" }}
              </div>
            </div>
            <hr />

            <div class="transaction-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="transaction-title">
                    Canceled Interview's Candidates
                  </h6>
                </div>
              </div>
              <div class="font-weight-bolder">
                {{
                  jobReports.canceledInterview
                    ? jobReports.canceledInterview
                    : "0"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BLink,
} from "bootstrap-vue";
import CandidateStatistics from "./partials/CandidateStatistics.vue";
import CandidateStatisticsMonthly from "./partials/CandidateStatisticsMonthly.vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    CandidateStatistics,
    CandidateStatisticsMonthly,
  },
  data() {
    return {
      jobReports: {},
      search: {
        from_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to_date: new Date(),
      },
    };
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.dashboard;
    },
  },

  mounted() {
    this.getJobReports();
  },
  methods: {
    getJobReports() {
      axios
        .get("/app/dashboard/job-statistics")
        .then((res) => {
          this.jobReports = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
  },
};
</script>

<style></style>
